import { useCache } from '@/hooks/web/useCache'
// import { decrypt, encrypt } from '@/utils/jsencrypt'
// import { useUserStore } from '@/stores/user'

// const userStore = useUserStore()
interface TokenType {
  id: string // 编号
  accessToken: string // 访问令牌
  refreshToken: string // 刷新令牌
  clientId: string //客户端编号
  expires_in: number //过期时间
}

interface UserInfo {
  name: string
  type: string
}

const { wsCache } = useCache()

const AccessTokenKey = 'ACCESS_TOKEN'
const RefreshTokenKey = 'REFRESH_TOKEN'

// 获取token
export const getAccessToken = () => {
  // 此处与TokenKey相同，此写法解决初始化时Cookies中不存在TokenKey报错
  return wsCache.get(AccessTokenKey) ? wsCache.get(AccessTokenKey) : wsCache.get('ACCESS_TOKEN')
}

// 获取刷新token
export const getRefreshToken = () => {
  return wsCache.get(RefreshTokenKey)
}

// 设置token
export const setToken = (token: TokenType) => {
  wsCache.set(RefreshTokenKey, token.refreshToken, { exp: token.expires_in })
  wsCache.set(AccessTokenKey, token.accessToken)
}

// 删除token
export const removeToken = () => {
  wsCache.delete(AccessTokenKey)
  wsCache.delete(RefreshTokenKey)
}

// 设置cookie
export const setCookie = (name: string, value: any, day: number) => {
  const date = new Date()
  date.setDate(date.getDate() + day)
  // document.cookie =
  //   name +
  //   '=' +
  //   value +
  //   ';expires=' +
  //   date +
  //   ';Domain=' +
  //   import.meta.env.VITE_COOKIE_DOMAIN +
  //   '; Path=/'
}

// 获取cookie
export const getCookie = (name: string) => {
  const reg = RegExp(name + '=([^;]+)')
  const arr = document.cookie.match(reg)
  if (arr) {
    return arr[1]
  } else {
    return ''
  }
}

// 删除cookie
export const delCookie = (name: string) => {
  setCookie(name, null, -1)
}

// 清除所有有效缓存
export const clearAll = () => {
  wsCache.clear()
  // userStore.setLoginStatus(false)
}

// 设置sessionState
export const setSessionState = (value: any) => {
  localStorage.setItem('sessionState', JSON.stringify(value))
}

// 设置用户信息
export const setUserInfo = (value: UserInfo) => {
  wsCache.set('USER_INFO', value)
}

// 获取用户信息
export const getUserInfo = () => {
  return wsCache.get('USER_INFO')
}

// 删除用户信息
export const removeUserInfo = () => {
  wsCache.delete('USER_INFO')
}
