<template>
  <vue-pdf-app style="height: 100vh" :pdf="url"></vue-pdf-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import VuePdfApp from 'vue3-pdf-app'
import 'vue3-pdf-app/dist/icons/main.css'

export default defineComponent({
  name: 'PdfViewer',
  components: {
    VuePdfApp
  },
  props: {
    url: {
      type: String,
      required: true
    }
  }
})
</script>
