
import type { RouteRecordRaw } from 'vue-router'

// 移动端路由表

export const mobileRoutes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: () => import('../views/mobile/home/mobileIndex.vue')
    }
]