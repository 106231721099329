import { createApp } from 'vue'
// import { createPinia } from 'pinia'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import 'reset.css'
import './assets/style/index.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn.mjs'
import './permission'

// keyclock 插件
// import VueKeycloakJs from '@dsb-norge/vue-keycloak-js'
// import Keycloak from 'keycloak-js'
// import type { VueKeycloakInstance } from '@dsb-norge/vue-keycloak-js/dist/types'
// import { setToken, setUserInfo, removeUserInfo } from '@/utils/auth'

zhCn.el.pagination.total = '共：' + `{total}` + '条'
const app = createApp(App)

app.use(router)
app.use(ElementPlus, {
  locale: zhCn
})
// app.use(VueKeycloakJs, {
//   init: {
//     onLoad: 'check-sso',
//     silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
//   },
//   config: {
//     url: import.meta.env.VITE_KEYCLOAK_URL,
//     clientId: import.meta.env.VITE_KEYCLOAK_CLIENTID,
//     realm: import.meta.env.VITE_KEYCLOAK_REALM
//   },
//   onReady(keycloak: Keycloak) {
//     console.log('keycloak ready', keycloak)
//     let token = {
//       access_token: keycloak.token,
//       refresh_token: keycloak.refreshToken
//     } as any
//     setToken(token)
//     if (keycloak.tokenParsed?.account) {
//       setUserInfo(keycloak.tokenParsed?.account)
//     } else {
//       removeUserInfo()
//     }
//   }
// })

// declare module '@vue/runtime-core' {
//   interface ComponentCustomProperties {
//     $keycloak: VueKeycloakInstance
//   }
// }

app.mount('#app')
