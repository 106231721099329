<template>
  <div id="zq-header" class="zq-layout-header">
    <div class="zq-header-top">
      <!-- 上半区：显示 logo 名称 控制台 -->
      <div class="zq-header-top-logo">
        <img src="@/assets/index/home-logo.png" alt="" />
      </div>
      <ul class="zq-header-bottom-menu">
        <li v-for="item of menuList" :key="item.id" @click="tabChange(item)">
          {{ item.name }}
          <div :class="{ activate: currentIndex === item.id }" class="line"></div>
        </li>
      </ul>
      <ul class="zq-header-top-action">
        <li @click="jumpToBottom"><img src="@/assets/imgs/phone.png" /> 联系我们</li>
        <li @click="to" v-if="isLogin"><img src="@/assets/imgs/ac.png" /> 控制台</li>
        <li>
          <div class="zq-header-top-login" v-if="isLogin" style="cursor: auto">
            <img src="@/assets/imgs/pel.png" /> {{ userName }}
          </div>
          <div class="zq-header-top-login" v-else @click="navTo">
            <img src="@/assets/imgs/pel.png" /> {{ '登录' }}
          </div>
          <div v-if="authUtil.getAccessToken()" class="zq-header-top-login" @click="logout">
            退出登录
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!-- <el-dialog v-model="dialogVisible" width="30%">
    <div style="text-align: center">
      <p style="margin-bottom: 10px">联系人：李奕龙</p>
      <p>邮箱：liyilong@hn.chinamobile.com</p>
    </div>
  </el-dialog> -->
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import * as authUtil from '@/utils/auth'
import { useUserStore } from '@/stores/modules/user'
import { ElMessage } from 'element-plus'

const userStore = useUserStore()
const currentIndex = ref<string | number>(localStorage.getItem('path') || '/')
const router = useRouter()

const userName = ref(userStore.nickname)
watch(
  () => userStore.nickname,
  (val) => {
    userName.value = val
  }
)
const isLogin = ref(userStore.isLogin)
watch(
  () => userStore.isLogin,
  (val) => {
    isLogin.value = val
  }
)

interface MenuList {
  id: string
  name: string
  path: string
}
const route = useRoute()
const menuList = ref<Array<MenuList>>([
  {
    id: '/',
    name: '首页',
    path: '/'
  },
  {
    id: '/scheme',
    name: '解决方案',
    path: '/scheme'
  },
  // {
  //   id: '/product',
  //   name: '政企产品',
  //   path: '/product'
  // },
  {
    id: '/atom',
    name: '原子能力',
    path: '/atom'
  }
  // {
  //   id: '/case',
  //   name: '应用案例',
  //   path: '/case'
  // }
])

const tabChange = (item: MenuList) => {
  currentIndex.value = item.id
  localStorage.setItem('path', item.id)
  router.push(item.path)
}

const navTo = () => {
  router.push('/login' + '?redirect=' + route.fullPath)
}

const to = () => {
  window.open(import.meta.env.VITE_CONSOLE_URL, '_blank')
}

const logout = async () => {
  await userStore.logOut()
  ElMessage.success('退出登录成功')
}

watch(route, (to, form) => {
  console.log(to, form, '路由变化')
  localStorage.setItem('path', to.meta.title as string)
  currentIndex.value = to.meta.title as string
})

const jumpToBottom = () => {
  // 跳转到底部id为zq-footer的元素
  const footer = document.getElementById('zq-footer')
  footer?.scrollIntoView({ behavior: 'smooth' })
}
</script>

<style lang="scss" scoped>
.zq-layout-header {
  width: 100%;
  height: 64px;
  padding: 64px;
  padding-top: 0;
  padding-bottom: 0;
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  .zq-header-top {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-logo {
      img {
        width: 316px;
        height: 37px;
        object-fit: contain;
      }
    }
    &-action {
      display: flex;
      li {
        padding: 16px;
        padding-top: 0;
        padding-bottom: 0;
        color: #ffffff;
        display: flex;
        font-size: 14px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }
        &:last-child {
          border-left: 1px solid #fff;
          border-left-style: solid;
          border-left-color: rgba($color: #fff, $alpha: 0.3);
        }
        .zq-header-top-login {
          height: 24px;
          min-width: 66px;
          padding: 0 10px;
          color: #ffffff;
          display: flex;
          font-size: 14px;
          justify-content: center;
          align-items: center;
          border-radius: 30px;
          background-color: #4187ff;

          &:last-child {
            margin-left: 20px;
          }
        }
      }
    }
  }
  .zq-header-bottom-menu {
    // width: 30%;
    height: 100%;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    li {
      padding: 4px;
      position: relative;
      color: #333333;
      font-size: 20px;
      cursor: pointer;
      margin: 0 30px;
      box-sizing: border-box;
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 400;

      .line {
        position: absolute;
        width: 0px;
        height: 4px;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 4px;
        background-color: #4187ff;
        border-radius: 4px;
        transition: all 0.5 ease;
      }
      .activate {
        width: 40px;
      }
    }
  }

  .zq-header-top-action {
    li {
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 14px;
      text-align: right;
      font-style: normal;
    }

    img {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
