import router from './router'
import { getAccessToken } from './utils/auth'
import { useUserStore } from '@/stores/modules/user'

const userStore = useUserStore()

router.beforeEach(async (to, from, next) => {
  if (getAccessToken()) {
    userStore.setUserInfo()
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      next()
    }
  } else {
    next()
  }
})
