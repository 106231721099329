import type { RouteRecordRaw } from 'vue-router'
import layoutTemplateVue from '@/layout/zq-template.vue'
import Login from '@/views/pc/login/pc-login.vue'
import PdfViewer from '@/components/pdf/index.vue'
// pc端路由表

export const pcRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: layoutTemplateVue,
    children: [
      {
        path: '',
        component: () => import('@/views/pc/home/pcIndex.vue'),
        meta: {
          title: '/'
        }
      },
      {
        path: '/scheme',
        component: () => import('@/views/pc/scheme/pc-scheme.vue'),
        meta: {
          title: '/scheme'
        }
      },
      {
        path: '/scheme-detail',
        component: () => import('@/views/pc/scheme/pc-scheme-detail.vue'),
        meta: {
          title: '/scheme'
        }
      },
      {
        path: '/product',
        component: () => import('@/views/pc/product/pc-product.vue'),
        meta: {
          title: '/product'
        }
      },
      {
        path: '/product-detail',
        component: () => import('@/views/pc/product/pc-product-detail.vue'),
        meta: {
          title: '/product'
        }
      },
      {
        path: '/atom',
        component: () => import('@/views/pc/atom/pc-atom.vue'),
        meta: {
          title: '/atom'
        }
      },
      {
        path: '/atom-detail',
        component: () => import('@/views/pc/atom/pc-atom-detail.vue'),
        meta: {
          title: '/atom'
        }
      },
      {
        path: '/case',
        component: () => import('@/views/pc/case/pc-case.vue'),
        meta: {
          title: '/case'
        }
      },
      {
        path: '/case-detail',
        component: () => import('@/views/pc/case/pc-case-detail.vue'),
        meta: {
          title: '/case'
        }
      }
    ]
  },
  {
    path: '/login',
    component: Login,
    meta: {
      name: '登录'
    }
  },
  {
    path: '/view-pdf',
    name: 'PdfViewer',
    component: PdfViewer,
    props: (route) => ({ url: route.query.url })
  },
  {
    path: '/officeView',
    name: 'officeViewer',
    component: () => import('@/components/office/index.vue'),
    props: (route) => ({ url: route.query.url })
  }
]
