import request from '@/config/axios'
import { getRefreshToken } from '@/utils/auth'

// 获取keycloak用户信息
export const getKeycloakInfo = (params: any) => {
  return request.getOriginal({
    url: import.meta.env.VITE_KEYCLOAK_URL + '/realms/master/protocol/openid-connect/userinfo',
    params,
    isToken: true as boolean
  })
}

// keycloak api调用
export const keycloakLogin = (params: any) => {
  const data = {
    grant_type: 'password',
    client_id: import.meta.env.VITE_KEYCLOAK_CLIENTID,
    client_secret: '',
    username: params.username,
    password: params.password,
    scope: 'openid'
  }
  return request.postOriginal({
    url: import.meta.env.VITE_KEYCLOAK_URL + '/realms/master/protocol/openid-connect/token',
    data,
    headersType: 'application/x-www-form-urlencoded'
  })
}

// 退出登录
export const logout = () => {
  const refresh_token = getRefreshToken()
  return request.post({
    url: import.meta.env.VITE_KEYCLOAK_URL + '/realms/master/protocol/openid-connect/logout',
    data: {
      client_id: import.meta.env.VITE_KEYCLOAK_CLIENTID,
      client_secret: '',
      refresh_token: refresh_token
    },
    headersType: 'application/x-www-form-urlencoded'
  })
}

// 刷新token
export const refreshToken = () => {
  const refresh_token = getRefreshToken()
  const data = {
    grant_type: 'refresh_token',
    client_id: import.meta.env.VITE_KEYCLOAK_CLIENTID,
    client_secret: '',
    refresh_token: refresh_token,
    scope: 'openid'
  }
  return request.postOriginal({
    url: import.meta.env.VITE_KEYCLOAK_URL + '/realms/master/protocol/openid-connect/token',
    data,
    headersType: 'application/x-www-form-urlencoded'
  })
}

///////////////////////// 框架登录调用 /////////////////////////
// 登录
export const login = (params: any) => {
  return request.post({
    url: '/apis/system/auth/login',
    data: params
  })
}

// 退出登录
export const logoutSystem = () => {
  return request.post({
    url: '/apis/system/auth/logout',
    isToken: true
  })
}

// 获取用户信息
export const getUserInfo = () => {
  return request.get({
    url: '/apis/system/user/profile/get',
    isToken: true
  })
}

// 获取验证图片以及 token
export const getCode = (data: any) => {
  return request.postOriginal({ url: '/apis/system/captcha/get', data })
}

// 滑动或者点选验证
export const reqCheck = (data: any) => {
  return request.postOriginal({ url: '/apis/system/captcha/check', data })
}

export const getPublicKeyApi = () => {
  return request.post({ url: '/apis/system/auth/getPubicKey' })
}
