<template>
    <div class="basciForm">
        <el-form ref="formRef" :model="formModel" :rules="rules">
            <el-form-item label="" prop="mobile">
                <el-input  v-model="formModel.username"  :prefix-icon="Avatar" placeholder="请输入账号">
                </el-input>
            </el-form-item>
            <el-form-item label="">
                <div class="code-btn">
                <el-input  v-model="formModel.code" style="width: 60%;" maxlength="6"  :prefix-icon="CircleCheck" placeholder="请输入验证码" />
                <el-button class="btn" style="width: 33%;height: 2.08vw;border: 1px solid #0077FF;color: #0077FF;" size="large" round>验证码</el-button>
                </div>
            </el-form-item>
            <div class="action">
                <div class="login" @click="login(formRef)">登录</div>
                <div class="logup">注册</div>
            </div>
            
        </el-form>
    
    </div>
    </template>
    
    <script setup lang="ts">
    import { reactive, ref } from 'vue'
    import { Avatar, Lock, CircleCheck } from '@element-plus/icons-vue'
    import {useRouter} from 'vue-router'
    import type { FormInstance, FormRules } from 'element-plus'
    const router = useRouter()
    
    interface Form {
        mobile: string
        code: string
        uuid: string
    }
    const formModel = ref<Form>({
        mobile: '',
        code: '',
        uuid: ''
    })
    const formRef = ref<FormInstance>()
    const rules = reactive<FormRules<Form>>({
        mobile: [{
            required: true,
            message: '账号不能为空',
            trigger: 'blur'
        }]
    })
    
    const login = async (formEl: FormInstance | undefined) => {
        console.log(formModel.value);
        if (!formEl) return
        const valid = await formEl.validate();
        if(!valid) return
        localStorage.setItem('isLogin', '1')
        router.push('/')
    }
    </script>
    
    <style lang="scss" scoped>
    .basciForm {
        width: 360px;
        margin: 24px auto;
        .code-btn {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .action {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 20px;
            .login {
                width: 360px;
                height: 48px;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                background: linear-gradient( 135deg, #4187FF 0%, #3656B3 100%);
                border-radius: 24px;
                box-shadow: 0px 8px 8px 0px rgba(54,86,179,0.2);
                margin-bottom: 24px;
                cursor: pointer;
            }
            .logup {
                width: 360px;
                height: 48px;
                color: #0077FF;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #FFFFFF;
                border-radius: 24px 24px 24px 24px;
                border: 1px solid #0077FF;
                cursor: pointer;
            }
        }
    }
    :deep(.el-form-item) {
        margin-bottom: 32px;
    }
    :deep(.el-input__wrapper) {
        height: 40px;
        border-radius: 30px;
        border: 1px solid #4187FF;
        .el-input__prefix {
            color: #4187FF;
            font-size: 16px;
        }
    }
    </style>