<template>
  <div id="zq-footer" class="zq-layout-footer">
    <div class="zq-layout-footer-top">
      <div class="first">
        <div class="logo_box">
          <img class="logo" src="@/assets/index/home-logo.png" />
        </div>
        <div class="content">
          <div class="content_left">
            <!--
            <div class="qrcode">
              <img src="@/assets/imgs/gzh.png" />
              <div class="qrcode-text">
                <p>关注公众号</p>
              </div>
            </div>
            -->
          </div>
          <div class="content_right">
            <p>联系我们</p>
            <ul class="container">
              <li>
                <img src="@/assets/imgs/footer-phone.png" />
                <div class="container-right">
                  <span class="phone">18373144536</span>
                </div>
              </li>
              <li>
                <img src="@/assets/imgs/footer-email.png" />
                <div class="container-right">
                  <span class="email">18373144536@139.com</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="second">
        <div class="site">解决方案</div>
        <p :ket="index" v-for="(item, index) in solution_list" @click="jumpTo(item.name, 1)">
          {{ item.name }}
        </p>
      </div>
      <div class="second">
        <div class="site">原子能力</div>
        <p :ket="index" v-for="(item, index) in cap_list" @click="jumpTo(item.name, 2)">
          {{ item.name }}
        </p>
      </div>
    </div>
    <div class="zq-layout-footer-bottom">
      <div class="msg">
        <span></span>
        <span class="Copyright">Copyright ©2024-湖南移动三化二级平台</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import * as schemaApi from '@/api/schema'
import { formatCate } from '@/utils/formatCate'
import { useCache } from '@/hooks/web/useCache'

const { wsCache } = useCache()

const getSCateListApi = () => {
  schemaApi.getSCateList({ display_status: 1 }).then((res) => {
    const result = formatCate(res)
    solution_list.value = result
    wsCache.set('solution_cate', result)
  })
}

const router = useRouter()

interface TabList {
  name: string
}

const solution_list = ref<TabList[]>([])
const cap_list = ref<TabList[]>([
  {
    name: '交通'
  },
  {
    name: '商业'
  },
  {
    name: '工业'
  },
  {
    name: '金融'
  },
  {
    name: '文旅'
  },
  {
    name: '农业'
  },
  {
    name: '医卫'
  },
  {
    name: '教育'
  },
  {
    name: '智慧城市'
  }
])

const jumpTo = (name: string, type: number) => {
  if (type === 1) {
    router.replace('/scheme?realm=' + name)
  } else {
    router.replace('/atom?realm=' + name + '&field=realm')
  }
}

onMounted(() => {
  getSCateListApi()
})
</script>

<style lang="scss" scoped>
.zq-layout-footer {
  width: calc(100% - 480px);
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #e4f0ff;
  padding: 0 240px;

  &-top {
    width: 100%;
    height: 210px;
    padding: 88px 0;
    box-sizing: border-box;
    display: flex;
    flex: 1;

    .first {
      width: 344px;
      height: 100%;
      font-family:
        PingFang SC,
        PingFang SC;
      font-size: 18px;
      color: #333333;
      line-height: 24px;
      text-align: left;
      font-style: normal;
      box-sizing: border-box;
      margin-right: auto;

      p {
        font-family:
          PingFang SC,
          PingFang SC;
        font-size: 20px;
        color: #333333;
        line-height: 26px;
        text-align: left;
        font-style: normal;
      }

      .container {
        margin-top: 40px;
        width: 100%;
        display: flex;
        flex-direction: column;

        li {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          img {
            width: 24px;
            height: 24px;
            margin-right: 12px;
          }

          .container-right {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;

            span {
              font-size: 14px;
              color: rgba($color: #fff, $alpha: 0.8);
            }

            .phone {
              font-family:
                PingFang SC,
                PingFang SC;
              font-size: 20px;
              color: #333333;
              line-height: 23px;
              text-align: left;
              font-style: normal;
            }

            .email {
              font-family:
                PingFang SC,
                PingFang SC;
              font-size: 20px;
              color: #333333;
              line-height: 23px;
              text-align: left;
              font-style: normal;
            }
          }
        }
      }
    }
    .second {
      margin-left: 80px;
      .site {
        font-family:
          PingFang SC,
          PingFang SC;
        font-size: 18px;
        color: #333333;
        line-height: 24px;
        text-align: left;
        font-style: normal;
      }
      p {
        font-family:
          PingFang SC,
          PingFang SC;
        font-size: 14px;
        color: #666666;
        line-height: 19px;
        text-align: right;
        font-style: normal;
        margin-top: 16px;
        cursor: pointer;
      }
    }
  }
  &-bottom {
    margin-top: auto;
    width: 100%;
    height: 55px;
    background: #e4f0ff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #e0e7eb;

    .msg {
      font-family:
        PingFang SC,
        PingFang SC;
      font-size: 12px;
      color: #999999;
      line-height: 16px;
      text-align: left;
      font-style: normal;
      .Copyright {
        margin-left: 24px;
      }
    }
  }
}

.logo_box {
  width: 316px;
  //   height: 37px;
  margin-bottom: 60px;

  .logo {
    width: 100%;
    height: 100%;
  }
}

.content {
  display: flex;

  .content_left {
    .qrcode {
      img {
        width: 102px;
        height: 102px;
      }

      .qrcode-text {
        p {
          font-family:
            PingFang SC,
            PingFang SC;
          font-size: 16px;
          color: #999999;
          text-align: center;
          font-style: normal;
        }
      }
    }
  }

  .content_right {
    margin-left: 80px;
  }
}
</style>
