import axios from 'axios'
import type {
  AxiosInstance,
  InternalAxiosRequestConfig,
  AxiosError,
  AxiosRequestHeaders,
  AxiosResponse
} from 'axios'
import { config } from '@/config/axios/config'
import { setToken, removeToken, getRefreshToken, getAccessToken } from '@/utils/auth'
import { ElNotification } from 'element-plus'
import qs from 'qs'
import { useUserStore } from '@/stores/modules/user'

const ignoreMsgs = [
  '无效的刷新令牌', // 刷新令牌被删除时，不用提示
  '刷新令牌已过期' // 使用刷新令牌，刷新获取新的访问令牌时，结果因为过期失败，此时需要忽略。否则，会导致继续 401，无法跳转到登出界面
]

const { result_code, base_url, request_timeout } = config

let requestList: any[] = []
// 是否正在刷新中
let isRefreshToken = false

const service: AxiosInstance = axios.create({
  baseURL: base_url,
  timeout: request_timeout,
  withCredentials: false
})

// request请求拦截器
service.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    let isToken = (config!.headers || {}).isToken === 'true'
    if (getAccessToken() && isToken) {
      config.headers.Authorization = 'Bearer ' + getAccessToken() // 让每个请求携带自定义token
      // 清除istoken字段
      config.headers.delete('Istoken')
    }
    const params = config.params || {}
    const data = config.data || false
    if (
      config.method?.toUpperCase() === 'POST' &&
      (config.headers as AxiosRequestHeaders)['Content-Type'] ===
        'application/x-www-form-urlencoded'
    ) {
      config.data = qs.stringify(data)
    }

    // get参数编码
    if (config.method?.toUpperCase() === 'GET' && params) {
      let url = config.url + '?'
      for (const propName of Object.keys(params)) {
        const value = params[propName]
        if (value !== void 0 && value !== null && typeof value !== 'undefined') {
          if (typeof value === 'object') {
            for (const val of Object.keys(value)) {
              const params = propName + '[' + val + ']'
              const subPart = encodeURIComponent(params) + '='
              url += subPart + encodeURIComponent(value[val]) + '&'
            }
          } else {
            url += `${propName}=${encodeURIComponent(value)}&`
          }
        }
      }
      // 给 get 请求加上时间戳参数，避免从缓存中拿数据
      // const now = new Date().getTime()
      // params = params.substring(0, url.length - 1) + `?_t=${now}`
      url = url.slice(0, -1)
      config.params = {}
      config.url = url
    }

    return config
  },
  (error: AxiosError) => {
    // Do something with request error
    console.log('request', error) // for debug
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    const { data } = response

    // 未设置状态码则默认成功状态
    const code = data.code || result_code
    if (
      response.request.responseType === 'blob' ||
      response.request.responseType === 'arraybuffer'
    ) {
      return response.data
    }

    const msg = data.msg
    if (ignoreMsgs.indexOf(msg) !== -1) {
      // 如果是忽略的错误码，直接返回 msg 异常
      return Promise.reject(msg)
    } else if (code == 401) {
      refresh(response.config)
    } else if (code !== 200) {
      if (msg === '访问令牌不存在') {
        return handleAuthorized()
        // hard coding：忽略这个提示，直接登出
      } else {
        ElNotification.error({ title: msg })
      }
      return Promise.reject('error')
    } else {
      return data
    }
  },
  (error: AxiosError) => {
    console.log('err', error) // for debug
    return Promise.reject(error)
  }
)

const refreshToken = async () => {
  return await axios.post(
    base_url + '/apis/system/auth/refresh-token?refreshToken=' + getRefreshToken()
  )
}

const refresh = async (config: any) => {
  if (!isRefreshToken) {
    isRefreshToken = true
    // 1. 如果获取不到刷新令牌，则只能执行登出操作
    if (!getRefreshToken()) {
      return handleAuthorized()
    }
    // 2. 进行刷新访问令牌
    try {
      const refreshTokenRes = await refreshToken()
      // 2.1 刷新成功，则回放队列的请求 + 当前请求
      setToken((await refreshTokenRes).data.data)
      config.headers!.Authorization = 'Bearer ' + getAccessToken()
      requestList.forEach((cb: any) => {
        cb()
      })
      requestList = []
      return service(config)
    } catch (e) {
      // 为什么需要 catch 异常呢？刷新失败时，请求因为 Promise.reject 触发异常。
      // 2.2 刷新失败，只回放队列的请求
      requestList.forEach((cb: any) => {
        cb()
      })
      // 提示是否要登出。即不回放当前请求！不然会形成递归
      return handleAuthorized()
    } finally {
      requestList = []
      isRefreshToken = false
    }
  } else {
    // 添加到队列，等待刷新获取到新的令牌
    return new Promise((resolve) => {
      requestList.push(() => {
        config.headers!.Authorization = 'Bearer ' + getAccessToken() // 让每个请求携带自定义token 请根据实际情况自行修改
        resolve(service(config))
      })
    })
  }
}

const handleAuthorized = () => {
  const user = useUserStore()
  user.setLoginStatus(false)
  removeToken()
  return Promise.reject('登录超时,请重新登录')
}
export { service }
