import request from '@/config/axios'

// 方案部分
// 获得方案分页
export const getSolutionPageApi = async (params: any) => {
  return await request.get({
    url: '/apis/first.level.platform.digitalnotee.api/solution/v1alpha1/website/pagination',
    params
  })
}

// 获得方案详情
export const getSolutionDetailApi = async (id: string) => {
  return await request.get({
    url: `/apis/first.level.platform.digitalnotee.api/solution/v1alpha1/website/${id}`
  })
}

// 获取解决方案类目列表
export const getSCateList = async (params: any) => {
  return await request.get({
    url: '/apis/first.level.platform.digitalnotee.api/category/v1alpha1/list',
    params,
    isToken: false
  })
}

// 评价
// 创建解决方案评价
export const createSolutionEvaluateApi = async (data: any) => {
  return await request.post({
    url: '/apis/first.level.platform.digitalnotee.api/solution-evaluate/v1alpha1',
    data
  })
}

// 获取解决方案评价分页
export const getSolutionEvaluateListApi = async (params: any) => {
  return await request.get({
    url: '/apis/first.level.platform.digitalnotee.api/solution-evaluate/v1alpha1/website/pagination',
    params
  })
}
