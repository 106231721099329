import { defineStore } from 'pinia'
import * as LoginApi from '@/api/login'
import { removeToken } from '@/utils/auth'
import { useCache } from '@/hooks/web/useCache'
import { store } from '../index'

const { wsCache } = useCache()
interface accountVO {
  name: string
  id: string
  type: string
  nickname: string
}

interface organizationUserVO {
  id: string
  display_name: string
}

interface UserStore {
  account: accountVO[]
  email: string
  email_verified: boolean
  family_name: string
  given_name: string
  organization_user: organizationUserVO[]
  preferred_username: string
  sub: string
  isLogin: boolean
  nickname: string
  realm: string
}

export const useUserStoreWith = defineStore('userStore', {
  state: (): UserStore => {
    return {
      account: [],
      email: '',
      email_verified: false,
      family_name: '',
      given_name: '',
      organization_user: [],
      preferred_username: '',
      sub: '',
      isLogin: false,
      nickname: '',
      realm: ''
    }
  },
  getters: {
    getUserInfo(): any {
      return this.account
    }
  },
  actions: {
    setUserInfo() {
      LoginApi.getUserInfo().then((res: any) => {
        this.nickname = res.nickname
        this.isLogin = true
        wsCache.set('nickname', res.nickname)
        return new Promise((resolve) => {
          resolve(res)
        })
      })
    },
    setLoginStatus(status: boolean) {
      this.isLogin = status
    },
    logOut() {
      LoginApi.logoutSystem().then(() => {
        // wsCache.clear()
        this.isLogin = false
        removeToken()
        window.location.reload()
      })
    }
  }
})

export function useUserStore() {
  return useUserStoreWith(store)
}
