<template>
  <div class="basciForm">
    <el-form ref="formRef" :model="formModel" :rules="rules" status-icon>
      <el-form-item label="" prop="username">
        <el-input v-model="formModel.username" :prefix-icon="Avatar" placeholder="请输入账号">
        </el-input>
      </el-form-item>
      <el-form-item label="" prop="password">
        <el-input
          v-model="formModel.password"
          type="password"
          :prefix-icon="Lock"
          placeholder="请输入密码"
        >
        </el-input>
      </el-form-item>
      <div class="action">
        <div class="login" @click="getCode">登录</div>
      </div>
      <Verify
        ref="verify"
        :captchaType="captchaType"
        :imgSize="{ width: '400px', height: '200px' }"
        mode="pop"
        @success="login"
      />
    </el-form>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue'
import { Avatar, Lock } from '@element-plus/icons-vue'
import { useRouter } from 'vue-router'
import type { FormInstance, FormRules } from 'element-plus'
import { Verify } from '@/components/Verifition/index'

import * as LoginApi from '@/api/login/index'
import * as authUtil from '@/utils/auth'
import { useUserStore } from '@/stores/modules/user'

// 获取加密
import jsencrypt from 'jsencrypt'
const jsRsa = new jsencrypt()

const publicKeyStr = ref('')
const getPublicKey = async () => {
  try {
    const res = await LoginApi.getPublicKeyApi()
    publicKeyStr.value = res.publicKey
    formModel.value.uuid = res.uuid
    jsRsa.setPublicKey(publicKeyStr.value)
  } catch (err) {
    console.log(err)
  }
}

// 验证码
const verify = ref()
const captchaType = ref('blockPuzzle') // blockPuzzle 滑块 clickWord 点击文字
// 获取验证码
const getCode = async () => {
  verify.value.show()
}

const router = useRouter()
const userStore = useUserStore()

interface Form {
  username: string
  password: string
  code: string
  uuid: string
  captchaVerification: string
}
const formModel = ref<Form>({
  username: '',
  password: '',
  code: '',
  uuid: '',
  captchaVerification: ''
})
const formRef = ref<FormInstance>()
const rules = reactive<FormRules<Form>>({
  password: [
    {
      required: true,
      message: '密码不能为空',
      trigger: 'blur'
    }
  ],
  username: [
    {
      required: true,
      message: '账号不能为空',
      trigger: 'blur'
    }
  ]
})

// const login = async (formEl: FormInstance | undefined) => {
//     console.log(formModel.value);
//     if (!formEl) return
//     const valid = await formEl.validate();
//     if(!valid) return
//     localStorage.setItem('isLogin', '1')
//     router.push('/')
// }

// const loginRidrectTo = () => {
//   window.location.href =
//     import.meta.env.VITE_KEYCLOAK_URL +
//     '/realms/master/protocol/openid-connect/auth?client_id=' +
//     import.meta.env.VITE_KEYCLOAK_CLIENTID +
//     '&redirect_url=' +
//     import.meta.env.VITE_CONSOLE_URL +
//     '&response_type=code&scope=openid&state=123456'
// }

const login = async (params: any) => {
  formModel.value.captchaVerification = params.captchaVerification
  await getPublicKey()
  const pubData = Object.assign({}, formModel.value)
  pubData.password = jsRsa.encrypt(pubData.password) as string
  pubData.username = jsRsa.encrypt(pubData.username) as string
  await LoginApi.login(pubData).then(
    async (res) => {
      await authUtil.setToken(res)
      await authUtil.setSessionState(res.session_state)
      // document.cookie = `token=${JSON.stringify(data)}; expires=${new Date(
      //   new Date().getTime() + res.refresh_expires_in * 1000
      // )}; Domain=${import.meta.env.VITE_COOKIE_DOMAIN}; Path=/`
      // 每次登陆后用户登陆状态为true
      userStore.isLogin = true
      await userStore.setUserInfo()
      router.push(redirect_url.value || '/')
    },
    () => {
      // ElNotification.error({
      //   title: '登录失败',
      //   message: '用户名或密码错误'
      // })
      // username.value.validateMessage = '用户名或密码错误'
      // username.value.validateState = 'error'
      // password.value.validateMessage = '用户名或密码错误'
      // password.value.validateState = 'error'
      // setTimeout(() => {
      //   console.log('1')
      //   loginFormRef.value.clearValidate()
      // }, 2000)
    }
  )
}

const redirect_url = ref()
onMounted(() => {
  // 获取redirect
  redirect_url.value = router.currentRoute.value.query.redirect
  console.log(redirect_url.value)
})
</script>

<style lang="scss" scoped>
.basciForm {
  width: 360px;
  margin: 24px auto;
  .code-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .action {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    .login {
      width: 320px;
      height: 48px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(135deg, #4187ff 0%, #3656b3 100%);
      border-radius: 24px;
      box-shadow: 0px 8px 8px 0px rgba(54, 86, 179, 0.2);
      margin-bottom: 24px;
      cursor: pointer;
    }
    .logup {
      width: 360px;
      height: 48px;
      color: #0077ff;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      border-radius: 24px 24px 24px 24px;
      border: 1px solid #0077ff;
      cursor: pointer;
    }
  }
}
:deep(.el-form-item) {
  margin-bottom: 32px;
  padding: 0 22px;
}
:deep(.el-input__wrapper) {
  height: 40px;
  border-radius: 30px;
  border: 1px solid #4187ff;
  .el-input__prefix {
    color: #4187ff;
    font-size: 16px;
  }
}
</style>
@/stores/modules/user
