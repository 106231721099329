<template>
  <div class="login">
    <div class="login-container">
      <div class="login-container-form">
        <p class="logo">湖南移动三化二级平台</p>
        <!-- <ul class="login-type">
          <div class="line"></div>
          <li
            class="login-type-item"
            :class="{ active: currentTab === tab.id }"
            v-for="tab of tabList"
            :key="tab.id"
            @click="handleTabChange(tab)"
          >
            {{ tab.name }}
          </li>
        </ul> -->

        <div class="login-form">
          <Transition appear enter-active-class="animate__animated animate__fadeIn">
            <component :is="loginType ? BasicForm : MobileForm" />
          </Transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import BasicForm from './BasicForm.vue'
import MobileForm from './MobileForm.vue'
const currentTab = ref(1)
const lineStyleLeft = ref('0%')

// 判断邮箱手机号登录
const loginType = ref(true)
</script>

<style lang="scss" scoped>
@font-face {
  font-family: ShuHeiTi;
  src: url(../../../assets/fonts/alimamashuheiti.ttf);
}
.login {
  width: 100vw;
  height: 100vh;
  background: url(../../../assets/imgs/login-gackground.png) no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  &-container {
    width: 438px;
    height: 500px;
    background: linear-gradient(135deg, #41dfff 0%, #4187ff 34%, #3656b3 100%);
    box-shadow: 0px 20px 50px 0px rgba(32, 39, 103, 0.3);
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    &-form {
      width: 390px;
      height: 452px;
      background: #ffffff;
      border-radius: 16px;
      .logo {
        width: 100%;
        font-size: 40px;
        color: #4187ff;
        font-family: ShuHeiTi;
        font-weight: 700;
        line-height: 40px;
        text-align: center;
        margin-top: 32px;
      }
      .login-type {
        width: 100%;
        height: 100px;
        display: flex;
        padding: 0;
        margin: 0;
        position: relative;
        .line {
          width: 50%;
          height: 4px;
          position: absolute;
          bottom: 28px;
          left: v-bind(lineStyleLeft);
          background-color: #4187ff;
          display: inline-block;
          transition: all 0.5s;
        }
        .login-type-item {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          font-family: PingFang SC;
          color: #333;
          cursor: pointer;
        }
        .active {
          color: #4187ff;
        }
      }
      .login-form {
        width: 100%;
        overflow: hidden;
        margin-top: 50px;
      }
    }
  }
}
</style>
