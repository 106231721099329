import { createRouter, createWebHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import { ref, computed } from 'vue'
import { mobileRoutes } from './mobile.data'
import { pcRoutes } from './pc.data'
const isMobile = ref(false)

const _isMobile = (): boolean => {
  const flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  const type = flag ? 'mobile' : 'pc'
  console.log(flag ? true : false);

  isMobile.value = flag ? true : false
  if (!localStorage.getItem('isMobile')) {
    // 如果没有缓存 那么就是第一次访问
    localStorage.setItem('isMobile', type)
  } else {
    // 如果有缓存，就不是第一次访问 需要判断当前的设备与上次使用的设备是否一致,不一致的话就要重新获取设备
    if (localStorage.getItem('isMobile') !== type) {
      localStorage.setItem('isMobile', type)

      window.location.reload()
    }
  }
}
_isMobile()


window.addEventListener('resize', () => {
  _isMobile()
})
const routes = computed(() => {
  console.log(isMobile.value);
  const route = isMobile.value ? mobileRoutes : pcRoutes
  console.log(route);
  
  return isMobile.value ? mobileRoutes : pcRoutes
})
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(ro, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { top: 0 }
  },
  routes: routes.value
})

export default router
