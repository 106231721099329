<template>
  <div class="zq-layout-main">
    <zqHeader></zqHeader>
    <section class="zq-section-main">
      <RouterView></RouterView>
    </section>
    <zqFooter></zqFooter>
  </div>
  <el-backtop style="background-color: #4187ff; color: #fff" :right="64" :bottom="100">
  </el-backtop>
</template>

<script setup lang="ts">
import zqHeader from '@/components/zq-header.vue'
import zqFooter from '@/components/zq-footer.vue'
import { onMounted, ref } from 'vue'
const height = ref<string>()

onMounted(() => {
  const header = document.getElementById('zq-header') as HTMLDivElement
  const footer = document.getElementById('zq-footer') as HTMLDivElement
  height.value = `calc(100vh - ${header.clientHeight + footer.clientHeight}px)`
  console.log(height.value)
})
</script>

<style lang="scss" scoped>
.zq-layout-main {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .zq-section-main {
    width: 100%;
    min-height: v-bind(height);
  }
}
</style>
